import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { MandatoryFields } from 'src/app/shared/models/mandatory-fields';
import { MandatoryFieldService } from 'src/app/shared/services/mandatory-field.service';
import { SolicitarAutenticacaoService } from 'src/app/shared/services/solicitar-autenticacao.service';

@Component({
  selector: 'app-mandatory-fields-form',
  templateUrl: './mandatory-fields-form.component.html',
  styleUrls: ['./mandatory-fields-form.component.css']
})
export class MandatoryFieldsFormComponent implements OnInit {

  mandatoryFieldsForm: FormGroup;
  carregandoCadastro: boolean = false;
  visivel: boolean = false;
  mandatoryField: MandatoryFields;

  constructor(
    @Inject(MAT_DIALOG_DATA) public mandatoryFieldEditar: MandatoryFields,
    private dialogRef: MatDialogRef<MandatoryFieldsFormComponent>,
    private fb: FormBuilder,
    private autenticacao: SolicitarAutenticacaoService,
    private notificacao: MatSnackBar,
    private mandatoryFieldService: MandatoryFieldService
  ) { }

  ngOnInit() {
    if (this.mandatoryFieldEditar !== null) {
      this.editMandatoryFields();
    }
    this.mandatoryFieldsForm = this.fb.group({
      id: [0],
      tableName: ['', Validators.required],
      fieldName: ['', Validators.required],
      mandatory: ['']
    })
  }

  closeModal() {
    this.dialogRef.close();
  }

  onSubmit() {
    if (this.mandatoryFieldsForm.valid) {
      this.createMandatoryFields(this.mandatoryFieldsForm.value);
    }
  }

  createMandatoryFields(mandatoryField) {
    this.carregandoCadastro = true;
    this.autenticacao.abrirDialogo().subscribe(response => {
      if (response) {

        this.mandatoryFieldService.create(mandatoryField).subscribe(result => {
          this.mandatoryFieldsForm.reset();
          this.carregandoCadastro = false;
          this.snackBarNotification('Campo Obrigatório cadastrado com sucesso!', 'sucesso');
          this.dialogRef.close(true);
        },
          err => {
            this.carregandoCadastro = false;
            this.snackBarNotification(err.error.message, 'erro');
          })

      } else {
        this.carregandoCadastro = false;
      }
    })
  }

  editMandatoryFields() {
    this.mandatoryFieldService.get(this.mandatoryFieldEditar.id).subscribe(result => {
      this.mandatoryField = result;
      this.mandatoryFieldsForm.patchValue({
        id: this.mandatoryField.id,
        tableName: this.mandatoryField.tableName,
        fieldName: this.mandatoryField.fieldName,
        mandatory: this.mandatoryField.mandatory
      })
    })
  }

  snackBarNotification(mensagem, tipo) {
    this.notificacao.open(mensagem, '', {
      duration: 4000,
      verticalPosition: 'top',
      horizontalPosition: 'end',
      panelClass: [tipo]
    });
  }

}
