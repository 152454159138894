import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Basic ${btoa('engine:engine')}`
    })
};

@Injectable({
    providedIn: 'root'
})
export class UsuariosSgiService {

    constructor(private http: HttpClient) {}

    cadastrarUsuario(body: any, res: any) {
        // tslint:disable-next-line:max-line-length
        return this.http.put(`${environment.caminhoApi}usuario-infraestruct?id=${res.id}&matricula=${body.matricula}&nome=${body.nome}`, body, httpOptions);
    }

}
