import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: `Basic ${btoa('engine:engine')}`,
  })
};

@Injectable({
  providedIn: 'root'
})
export class UsuariosService {

  idGrupo: number;
  idUsuario: number;
  usuarioData;

  constructor(private http: HttpClient) {
    this.usuarioData = JSON.parse(localStorage.getItem('usuarioData'));
    this.idGrupo = this.usuarioData.modulo.idGrupo;
    this.idUsuario = this.usuarioData.idUsuario;
  }

  listarUsuarios() {
    return this.http.get(`${environment.apiAdmin}usuario`, httpOptions);
  }

  getUsuario(id) {
    return this.http.get(`${environment.apiAdmin}usuario/${id}?idUsuarioLogado=${this.idUsuario}`, httpOptions);
  }

  cadastrarUsuario(body) {
    return this.http.post(`${environment.apiAdmin}usuario?idUsuarioLogado=${this.idUsuario}`, body, httpOptions);
  }

  revogarAcesso(idUser) {
    return this.http.patch(`${environment.apiAdmin}usuario/revogar-acesso/${idUser}?idUsuarioLogado=${this.idUsuario}`, null, httpOptions);
  }

  relatorModulo(idUser, idModulo) {
    return this.http.put(`${environment.apiAdmin}usuario/relator-modulo/${idUser}?idUsuarioLogado=${this.idUsuario}&idModulo=${idModulo}`, null, httpOptions);
  }

}
