import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { LoginModule } from './modules/login/login.module';
import { UsuariosModule } from './modules/usuarios/usuarios.module';
import { ModulosModule } from './modules/modulos/modulos.module';
import { MandatoryFieldsModule } from './modules/mandatory-fields/mandatory-fields.module';

import { MAT_DATE_LOCALE, DateAdapter } from '@angular/material';
import { CustomDateAdapter } from './custom.date.adapter';

import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';



@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,

    LoginModule,
    UsuariosModule,
    ModulosModule,
    MandatoryFieldsModule,

    MatButtonModule,
    MatMenuModule,
    MatIconModule,
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: DateAdapter, useClass: CustomDateAdapter }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
