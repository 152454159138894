import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Permissao } from '../models/permissao.model';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Basic ${btoa('engine:engine')}`
    })
};

@Injectable({
    providedIn: 'root'
})
export class PermissoesService {

    idGrupo: number;
    idUsuario: number;
    usuarioData;

    constructor(private http: HttpClient) {
        this.usuarioData = JSON.parse(localStorage.getItem('usuarioData'));
        this.idGrupo = this.usuarioData.modulo.idGrupo;
        this.idUsuario = this.usuarioData.idUsuario;
    }

    listarPermissoes(idSistema: string, idModulo: string) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<Permissao[]>(`${environment.apiAdmin}permissao-modulo?idUsuarioLogado=${this.idUsuario}&idSistema=${idSistema}&idModulo=${idModulo}`, httpOptions);
    }

    gravarPermissoes(data) {
        return this.http.post(`${environment.apiAdmin}permissao-modulo?idUsuarioLogado=${this.idUsuario}`, data, httpOptions);
    }

    removePermissao(id: number) {
        return this.http.delete(`${environment.apiAdmin}permissao-modulo/${id}?idUsuarioLogado=${this.idUsuario}`, httpOptions);
    }
}
