import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Basic ${btoa('engine:engine')}`
    })
  };

@Injectable({
    providedIn: 'root'
})
export class ModulosSgiService {

    constructor(private http: HttpClient) {}

    cadastrarModulo(modulo) {
        return this.http.put(`${environment.caminhoApi}modulo-infraestruct`, modulo, httpOptions)
    }

}
