import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ModulosService } from '../services/modulos.service';
import { SolicitarAutenticacaoService } from 'src/app/shared/services/solicitar-autenticacao.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ModulosSgiService } from '../services/modulos-sgi.service';
import { Modulo } from 'src/app/shared/models/modulo.model';

@Component({
  selector: 'app-cadastrar',
  templateUrl: './cadastrar.component.html',
  styleUrls: ['./cadastrar.component.css']
})
export class CadastrarComponent implements OnInit {
  formularioCadastrarModulo: FormGroup;
  carregandoCadastro: boolean = false;
  visivel: boolean = false;
  modulos: Modulo[] = [];
  modulo: Modulo;

  ngOnInit() {
    if (this.moduloEditar !== null) {
      this.carregarModulo();
    }
    this.carregarModulos();
    this.formularioCadastrarModulo = this.formBuilder.group(
      {
        id: [0],
        nome: ['', Validators.required],
        descricao: ['', Validators.required],
        color: ['', Validators.required],
        idModuloRetorno: ['']
      }
    );
  }

  onSubmit() {
    if (this.formularioCadastrarModulo.valid) {
      this.cadastrarModulo(this.formularioCadastrarModulo.value);
    }
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public moduloEditar: Modulo,
    private dialogRef: MatDialogRef<CadastrarComponent>,
    private formBuilder: FormBuilder,
    private modulosService: ModulosService,
    private modulosSgiService: ModulosSgiService,
    private autenticacao: SolicitarAutenticacaoService,
    private notificacao: MatSnackBar,
  ) { }

  closeModal() {
    this.dialogRef.close();
  }

  carregarModulos() {
    this.modulosService.listarModulos().subscribe(result => {
      this.modulos = result
    },
    err => {
      this.carregandoCadastro = false;
      this.snackBarNotification(err.error.message, 'erro');
    })
  }

  cadastrarModulo(modulo) {
    this.carregandoCadastro = true;
    // console.log(modulo)
    this.autenticacao.abrirDialogo()
      .subscribe(resposta => {
        if (resposta) {

          this.modulosService.cadastrarModulo(modulo)
            .subscribe(
              (res: any) => {
                const data: any = {
                  id: res.id,
                  nome: res.nome
                }
                this.modulosSgiService.cadastrarModulo(data).subscribe(result => {
                this.formularioCadastrarModulo.reset();
                this.carregandoCadastro = false;
                this.snackBarNotification('Modulo cadastrado com sucesso!', 'sucesso');
                // console.log(modulo);
                this.dialogRef.close(true);
                },
                err => {
                  this.carregandoCadastro = false;
                  this.snackBarNotification(err.error.message, 'erro');
                })

              },
              err => {
                this.carregandoCadastro = false;
                this.snackBarNotification(err.error.message, 'erro');
              }
            );

        } else {
          this.carregandoCadastro = false;
        }
      })
  }

  carregarModulo() {
    this.modulosService.carregarModulo(this.moduloEditar.id).subscribe(result => {
      this.modulo = result;
      // console.log(this.modulo)

      // Preencher campos do formulário com os valores do usuarioModulo
      this.formularioCadastrarModulo.patchValue({
        id: this.modulo.id,
        nome: this.modulo.nome,
        descricao: this.modulo.descricao,
        color: this.modulo.color,
        idModuloRetorno: this.modulo.idModuloRetorno
      });

    })
  }

  snackBarNotification(mensagem, tipo) {
    this.notificacao.open(mensagem, '', {
      duration: 4000,
      verticalPosition: 'top',
      horizontalPosition: 'end',
      panelClass: [tipo]
    });
  }

  mudarCor($event) {
    this.formularioCadastrarModulo.patchValue({ color: $event.color.hex });
  }
}
