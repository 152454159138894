import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NomeTituloService } from './shared/services/nometitulo.service';
import { LoginService } from 'src/app/modules/login/service/login-provider'
import { environment } from 'src/environments/environment';
import { VersionService } from './shared/services/version.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  nomePagina: string;
  nomeSecao: string;
  usuarioLogado: boolean = true;
  usuarioData = JSON.parse(localStorage.getItem('usuarioData'));
  corSetor:string = '#000000';

  nomeUsuario: string;
  matriculaUsuario: string;
  version = this.versionService.version;
  ambiente = environment.ambiente;

  constructor(
    private router: Router,
    private nomeTituloService: NomeTituloService,
    private versionService: VersionService,
  ) {

    this.nomeTituloService.path.subscribe(({ nomeSecao }) => {
      this.nomeSecao = nomeSecao;
    });

  }

  mostrarFuncionalidades(funcionalidade) {
    if (this.usuarioData) {
      return this.usuarioData.modulo.funcionalidades.find(f => f.identificador === funcionalidade);
    }
  }

  ngOnInit() {

    if (this.usuarioData !== null) {
      this.usuarioLogado = true;
    } else {
      this.usuarioLogado = false;
      this.router.navigate(['login']);
    }

    LoginService.usuarioLogado
      .subscribe(estado => {
        if (estado) {
          this.usuarioLogado = true;
          this.usuarioData = JSON.parse(localStorage.getItem('usuarioData'));
        } else {
          this.usuarioLogado = false;
          this.router.navigate(['login']);
        }
      })

  }

  realizarLogoff() {
    localStorage.removeItem('usuarioData');
    this.usuarioLogado = false;
    this.router.navigate(['login'])
  }
}
