import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: `Basic ${btoa('engine:engine')}`,
  })
};

@Injectable({
  providedIn: 'root'
})
export class UsuariosModulosSgiService {


  constructor(private http: HttpClient) {}

  vincularUsuarioModulo(data) {
    return this.http.put(`${environment.caminhoApi}usuarios-modulo-infraestruct`, data, httpOptions);
  }

}
