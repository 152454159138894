import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MandatoryFields } from '../models/mandatory-fields';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': `Basic ${btoa('engine:engine')}`
  })
};

@Injectable({
  providedIn: 'root'
})
export class MandatoryFieldService {

  permissionsLista = [
    { id: 1, idModulo: 1, idService: 1, chave: 'teste', descricao: 'Tramitar', nivelPermissao: 1 },
    { id: 1, idModulo: 1, idService: 1, chave: 'teste', descricao: 'Movimentar', nivelPermissao: 2 },
    { id: 1, idModulo: 1, idService: 1, chave: 'teste', descricao: 'Arquivar', nivelPermissao: 3 },
    { id: 1, idModulo: 1, idService: 1, chave: 'teste', descricao: 'Excluir', nivelPermissao: 3 },
  ]
  idGrupo: number;
  idUsuario: number;
  usuarioData;

  constructor(private http: HttpClient) {
    this.usuarioData = JSON.parse(localStorage.getItem('usuarioData'));
    this.idGrupo = this.usuarioData.modulo.idGrupo;
    this.idUsuario = this.usuarioData.idUsuario;
  }

  getAll() {
    return this.http.get<MandatoryFields[]>(`${environment.apiAdmin}mandatory-fields`, httpOptions)
  }

  create(data) {
    return this.http.post<MandatoryFields[]>(`${environment.apiAdmin}mandatory-fields?idUsuarioLogado=${this.idUsuario}`, data, httpOptions)
  }

  get(id: number) {
    return this.http.get<MandatoryFields>(`${environment.apiAdmin}mandatory-fields/${id}`, httpOptions)
  }

}
