import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SolicitarAutenticacaoModule } from 'src/app/shared/components/solicitar-autenticacao/solicitar-autenticacao.module';
import { MandatoryFieldsComponent } from './mandatory-fields.component';
import { MandatoryFieldsFormComponent } from './mandatory-fields-form/mandatory-fields-form.component';

import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatFormFieldModule, MatInputModule, MatButtonModule, MatIconModule } from '@angular/material';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatSelectModule } from '@angular/material/select';
import { ColorChromeModule } from 'ngx-color/chrome';

import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';


@NgModule({
  declarations: [
    MandatoryFieldsComponent,
    MandatoryFieldsFormComponent
  ],
  imports: [
    CommonModule,
    SolicitarAutenticacaoModule,
    MatTableModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    MatDialogModule,
    MatTooltipModule,
    MatChipsModule,
    MatDividerModule,
    ColorChromeModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatRadioModule,
    //MatCheckboxModule
  ],
  entryComponents: [
    MandatoryFieldsComponent,
    MandatoryFieldsFormComponent
  ]
})
export class MandatoryFieldsModule { }
