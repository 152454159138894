import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Modulo } from 'src/app/shared/models/modulo.model'
import { Observable, observable } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': `Basic ${btoa('engine:engine')}`
  })
};

@Injectable({
  providedIn: 'root'
})
export class ModulosService {

  permissionsLista = [
    { id: 1, idModulo: 1, idService: 1, chave: 'teste', descricao: 'Tramitar', nivelPermissao: 1 },
    { id: 1, idModulo: 1, idService: 1, chave: 'teste', descricao: 'Movimentar', nivelPermissao: 2 },
    { id: 1, idModulo: 1, idService: 1, chave: 'teste', descricao: 'Arquivar', nivelPermissao: 3 },
    { id: 1, idModulo: 1, idService: 1, chave: 'teste', descricao: 'Excluir', nivelPermissao: 3 },
  ]
  idGrupo: number;
  idUsuario: number;
  usuarioData;

  constructor(private http: HttpClient) {
    this.usuarioData = JSON.parse(localStorage.getItem('usuarioData'));
    this.idGrupo = this.usuarioData.modulo.idGrupo;
    this.idUsuario = this.usuarioData.idUsuario;
  }

  /*cadastrarUsuario(body) {
    return this.http.post(`${environment.apiAdmin}usuario?idUsuarioLogado=${this.idUsuario}`, body, httpOptions);
  }*/

  cadastrarModulo(body) {
    return this.http.post(`${environment.apiAdmin}modulos?idUsuarioLogado=${this.idUsuario}`, body, httpOptions);
  }

  listarModulos() {
    return this.http.get<Modulo[]>(`${environment.apiAdmin}modulos`, httpOptions);
  }

  carregarModulo(id: number) {
    return this.http.get<Modulo>(`${environment.apiAdmin}modulos/${id}?idUsuarioLogado=${this.idUsuario}`, httpOptions);
  }

  listarUsuariosModulos(idModulo) {
    return this.http.get(`${environment.apiAdmin}usuarios-modulo?idModulo=${idModulo}`, httpOptions);
  }

  listarPermissoesModulos(idModulo) {
    // return this.http.get(`${environment.apiAdmin}permissoes?idUsuarioLogado=${this.idUsuario}&idModulo=${idModulo}`, httpOptions);
    return new Observable(observavel => {
      setTimeout(() => {
        observavel.next(this.permissionsLista);
        observavel.complete();
      }, 1000);
    }
    )
  }
}
