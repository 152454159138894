
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Login } from '../models/login';
import { Usuario } from '../models/usuario';
import { timeout } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'Basic ' + btoa('engine:engine')
  })
};

@Injectable()
export class LoginService{

  static usuario = JSON.parse(localStorage.getItem('usuarioData'));

  static usuarioLogado = new EventEmitter<boolean>(LoginService.usuario !== null);

  constructor(private http: HttpClient) { }

  autenticarUsuario(login: Login): Observable<Usuario> {
    return this.http.post<Usuario>(environment.apiAdmin + `login`, login, httpOptions).pipe(
      timeout(10000)
    );
  }

  usuarioAutenticado(){
    LoginService.usuarioLogado.emit(true);
  }

}
