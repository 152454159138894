import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: `Basic ${btoa('engine:engine')}`,
  })
};

@Injectable({
  providedIn: 'root'
})
export class UsuariosModulosService {

  idGrupo: number;
  idUsuario: number;
  usuarioData;

  constructor(private http: HttpClient) {
    this.usuarioData = JSON.parse(localStorage.getItem('usuarioData'));
    this.idGrupo = this.usuarioData.modulo.idGrupo;
    this.idUsuario = this.usuarioData.idUsuario;
  }

  vincularUsuarioModulo(data) {
    return this.http.post(`${environment.apiAdmin}usuarios-modulo?idUsuarioLogado=${this.idUsuario}`, data, httpOptions);
  }

  carregarUsuarioModulo(idUsuario) {
    return this.http.get(`${environment.apiAdmin}usuarios-modulo/${idUsuario}/modulos?idUsuarioLogado=${this.idUsuario}`, httpOptions);
  }

}
