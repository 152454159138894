export class Modulo {
    color: string
    descricao: string
    dthCadastro: string
    flagAtivo: boolean
    id: number
    nome: string
    permissaoModulo: number
    idModuloRetorno: number
}
