import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UsuariosService } from '../services/usuarios.service';
import { SolicitarAutenticacaoService } from 'src/app/shared/services/solicitar-autenticacao.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UsuariosSgiService } from '../services/usuarios-sgi.service';

@Component({
  selector: 'app-cadastrar',
  templateUrl: './cadastrar.component.html',
  styleUrls: ['./cadastrar.component.css']
})
export class CadastrarComponent implements OnInit {
  formularioCadastrarUsuario: FormGroup;
  carregandoCadastro = false;
  visivel = false;

  ngOnInit() {
    this.formularioCadastrarUsuario = this.formBuilder.group(
      {
        login: ['', Validators.required],
        matricula: ['', Validators.required],
        nome: ['', Validators.required],
        email: ['', Validators.required],
        senha: ['', Validators.required],
      }
    );
  }

  onSubmit() {
    if (this.formularioCadastrarUsuario.valid) {
      this.cadastrarUsuario(this.formularioCadastrarUsuario.value);
    }
  }

  constructor(
    private dialogRef: MatDialogRef<CadastrarComponent>,
    private formBuilder: FormBuilder,
    private usuariosService: UsuariosService,
    private usuariosSgiService: UsuariosSgiService,
    private autenticacao: SolicitarAutenticacaoService,
    private notificacao: MatSnackBar,
  ) { }

  closeModal() {
    this.dialogRef.close();
  }

  cadastrarUsuario(usuario) {
    this.carregandoCadastro = true;
    // console.log(usuario)
    this.autenticacao.abrirDialogo()
    .subscribe(resposta => {
      if (resposta) {

        this.usuariosService.cadastrarUsuario(usuario)
        .subscribe(
          (res: any) => {
            const data: any = {
              analista: false,
              estagiario: false,
              gerente: true,
              id: res.id,
              matricula: usuario.matricula,
              nome: usuario.nome
            }
            this.usuariosSgiService.cadastrarUsuario(data, res).subscribe(result => {
              this.formularioCadastrarUsuario.reset();
              this.carregandoCadastro = false;
              this.snackBarNotification('Processo cadastrado com sucesso!', 'sucesso');
              console.log(data)
              console.log(usuario);
              console.log(res)
              this.dialogRef.close(true);
            },
            err => {
              this.carregandoCadastro = false;
              this.snackBarNotification(err.error.message, 'erro');
            })
          },
          err => {
            this.carregandoCadastro = false;
            this.snackBarNotification(err.error.message, 'erro');
          }
        );

      } else {
        this.carregandoCadastro = false;
      }
    })
  }

  snackBarNotification(mensagem, tipo) {
    this.notificacao.open(mensagem, '', {
      duration: 4000,
      verticalPosition: 'top',
      horizontalPosition: 'end',
      panelClass: [tipo]
    });
  }

}
