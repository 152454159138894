import { Component, OnInit, ViewChild } from '@angular/core';
import { ModulosService } from './services/modulos.service';
import { MatTableDataSource, MatPaginator } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { CadastrarComponent } from './cadastrar/cadastrar.component';
import { DetalharComponent } from './detalhar/detalhar.component';
import { Modulo } from 'src/app/shared/models/modulo.model';
import { PermicaoComponent } from './permicao/permicao.component';
import { TramitacaoComponent } from './tramitacao/tramitacao.component';

@Component({
  selector: 'app-modulos',
  templateUrl: './modulos.component.html',
  styleUrls: ['./modulos.component.css']
})
export class ModulosComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  carregamentoTabela: boolean = true;
  dados = new MatTableDataSource();
  colunas: string[] = [
    'id',
    'nome',
    'descricao',
    'cor',
    'cadastro',
    'editarModulo',
    'detalhar',
    'permissao',
    'tramitacao'
  ];

  constructor(
    public modulosService: ModulosService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.listarTodosModulos();
    this.dados.paginator = this.paginator;
  }

  listarTodosModulos() {
    this.carregamentoTabela = true;
    this.modulosService.listarModulos().
      subscribe(resposta => {
        this.dados.data = resposta
        this.carregamentoTabela = false;
        // console.table(resposta);
      }, error => {
        this.carregamentoTabela = false;
      })
  }

  busca(value: string) {
    this.dados.filter = value.trim().toLocaleLowerCase();
  }

  cadastrarModulo() {
    const dialogRef = this.dialog.open(CadastrarComponent);

    dialogRef.afterClosed().subscribe(resultado => {
      if (resultado) {
        this.listarTodosModulos();
      }
    });
  }

  editarModulo(modulo: Modulo) {
    const dialogRef = this.dialog.open(CadastrarComponent, {
      data: modulo
    });

    dialogRef.afterClosed().subscribe(resultado => {
      if (resultado) {
        this.listarTodosModulos();
      }
    });
  }

  detalharModulo(modulo: Modulo) {
    const dialogRef = this.dialog.open(DetalharComponent, {
      data: modulo,
      // maxWidth: '100vw',
      // maxHeight: '100vh',
      // height: '95%',
      // width: '95%',
    });
  }

  permissaoModulo(modulo: Modulo) {
    const dialogRef = this.dialog.open(PermicaoComponent, {
      data: modulo,
      // maxWidth: '100vw',
      // maxHeight: '100vh',
      // height: '95%',
      // width: '95%',
    });
  }

  tramitacaoModulo(modulo: Modulo) {
    const dialogRef = this.dialog.open(TramitacaoComponent, {
      data: modulo,
      // maxWidth: '100vw',
      // maxHeight: '100vh',
      // height: '95%',
      // width: '95%',
    });
  }

}
