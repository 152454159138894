import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { ModulosService } from '../services/modulos.service';
import { Modulo } from 'src/app/shared/models/modulo.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModuloTramitacaoService } from 'src/app/shared/services/modulo-tramitacao.service';
import { ModuloTramitacao } from 'src/app/shared/models/modulo-tramitacao';

@Component({
  selector: 'app-tramitacao',
  templateUrl: './tramitacao.component.html',
  styleUrls: ['./tramitacao.component.css']
})
export class TramitacaoComponent implements OnInit {

  formularioTramitacaoModulo: FormGroup;
  carregandoCadastro = false;
  visivel = false;
  carregamentoTabela: boolean = true;
  carregamentoPermission: boolean = true;
  modulos: Array<Modulo> = [];
  modulosDestino: Modulo[] = [];
  modulosTramitacao: Array<ModuloTramitacao> = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public modulo: Modulo,
    private dialogRef: MatDialogRef<TramitacaoComponent>,
    private formBuilder: FormBuilder,
    private notificacao: MatSnackBar,
    private modulosService: ModulosService,
    private moduloTramitacaoService: ModuloTramitacaoService,
  ) { }

  ngOnInit() {
    this.carregarModulos();
    this.carregarModuloTramitacao();
    this.formularioTramitacaoModulo = this.formBuilder.group(
      {
        moduloOrigen: [''], //, Validators.required
        modulosDestino: ['']
      }
    );
  }

  fecharTramitacao() {
    this.dialogRef.close();
  }

  snackBarNotification(mensagem, tipo) {
    this.notificacao.open(mensagem, '', {
      duration: 4000,
      verticalPosition: 'top',
      horizontalPosition: 'end',
      panelClass: [tipo]
    });
  }

  carregarModulos() {
    this.modulosService.listarModulos().subscribe(result => {
      this.modulos = result;
      this.modulos = this.modulos.filter(item => item.id !== this.modulo.id);
    }, error => {
      console.log(error);
    });
  }

  carregarModuloTramitacao() {
    this.moduloTramitacaoService.listarModuloTramitacao().subscribe(result => {
      this.modulosTramitacao = result
      this.modulosTramitacao = this.modulosTramitacao.filter(item => item.moduloOrigem.id === this.modulo.id);
    }, error => {
      console.log(error);
    });
  }

  vincularTramitacaoModulo() {
    this.carregandoCadastro = true;
    const moduloOrigen = this.modulo.id;
    const modulosDestino = this.modulosDestino.map(modulo => modulo.id);

    modulosDestino.forEach(moduloDestinoId => {
      const dataItem = {
        id: 0,
        moduloOrigem: {
          id: moduloOrigen
        },
        moduloDestino: {
          id: moduloDestinoId
        }
      };

      this.salvarTramitacaoModulo(dataItem);
    });

    this.carregandoCadastro = false;
  }

  salvarTramitacaoModulo(dataItem: any) {
    console.log(dataItem)

    this.moduloTramitacaoService.gravarModuloTramitacao(dataItem).subscribe(result => {
      this.snackBarNotification('Módulo Tramitação cadastrado com sucesso!', 'sucesso');
      this.dialogRef.close(true);
    }, error => {
      this.carregandoCadastro = false;
      this.snackBarNotification(error.error.message, 'erro');
    });
  }

  atualizarModulosDestino(checked: boolean, modulo: Modulo) {
    if (checked) {
      this.modulosDestino.push(modulo);
    } else {
      const index = this.modulosDestino.findIndex(mod => mod.id === modulo.id);
      if (index !== -1) {
        this.modulosDestino.splice(index, 1);
      }
    }
  }

  verificarModuloDestino(moduloDestinoId: number): boolean {
    return this.modulosTramitacao.some(item =>
      item.moduloOrigem.id === this.modulo.id && item.moduloDestino.id === moduloDestinoId
    );
  }

}
