import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ModuloTramitacao } from '../models/modulo-tramitacao';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Basic ${btoa('engine:engine')}`
    })
};

@Injectable({
    providedIn: 'root'
})
export class ModuloTramitacaoService {

    idGrupo: number;
    idUsuario: number;
    usuarioData;

    constructor(private http: HttpClient) {
        this.usuarioData = JSON.parse(localStorage.getItem('usuarioData'));
        this.idGrupo = this.usuarioData.modulo.idGrupo;
        this.idUsuario = this.usuarioData.idUsuario;
    }

    listarModuloTramitacao() {
        // tslint:disable-next-line:max-line-length
        return this.http.get<ModuloTramitacao[]>(`${environment.apiAdmin}modulos-tramitacao?idUsuarioLogado=${this.idUsuario}`, httpOptions);
    }

    gravarModuloTramitacao(data) {
        return this.http.post(`${environment.apiAdmin}modulos-tramitacao?idUsuarioLogado=${this.idUsuario}`, data, httpOptions);
    }
}
