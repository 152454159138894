import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SolicitarAutenticacaoService } from 'src/app/shared/services/solicitar-autenticacao.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ServicosService } from 'src/app/shared/services/servicos.service';
import { Servico } from 'src/app/shared/models/sevico.model';
import { ConstantesService } from 'src/app/shared/services/constantes.service';
import { PermissoesService } from 'src/app/shared/services/permissoes.service';
import { Permissao } from 'src/app/shared/models/permissao.model';

@Component({
  selector: 'app-permicao',
  templateUrl: './permicao.component.html',
  styleUrls: ['./permicao.component.css']
})
export class PermicaoComponent implements OnInit {

  carregamentoTabela: boolean = true;
  carregamentoPermission: boolean = true;
  servicos: Array<Servico> = [];
  permissoes: Array<Permissao> = [];
  constantes: any = [];
  selected: { [key: string]: string } = {};
  estagiario = false;
  colaborador = false;
  gestor = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public modulo,
    private dialogRef: MatDialogRef<PermicaoComponent>,
    private notificacao: MatSnackBar,
    private servicosService: ServicosService,
    private constantesService: ConstantesService,
    private permissoesService: PermissoesService,
  ) { }

  ngOnInit() {
    this.carregarServicos();
    this.buscarConstantes();
    this.buscarPermissoes('2', this.modulo.id);
  }

  fecharPermissao() {
    this.dialogRef.close();
  }

  // tslint:disable-next-line:max-line-length
  salvarPermissao(slideToggleChecked: boolean, valorSelect: string,
                  servicoId: string, moduloId: string, idPermissao: number,
                  estagiario: boolean, colaborador: boolean, gestor: boolean) {

    const flagColaborador = colaborador ? true : false;
    const flagEstagiario = estagiario ? true : false;
    const flagGestor = gestor ? true : false;

    const data = {
      flagAcessoColaborador: flagColaborador,
      flagAcessoEstagiario: flagEstagiario,
      flagAcessoGestor: flagGestor,
      modulo: {
        id: moduloId
      },
      servico: {
        id: servicoId
      }

    }

    if (slideToggleChecked) {
      console.log(data);
      this.permissoesService.gravarPermissoes(data).subscribe(result => {
        this.snackBarNotification('Permissão cadastrado com sucesso!', 'sucesso');
      }, error => {
        console.log(error);
      })
    } else {
      console.log('Estado do slide toggle:', slideToggleChecked);
      console.log(idPermissao)
      this.permissoesService.removePermissao(idPermissao).subscribe(result => {

      }, error => {
        console.log(error);
      })
    }


  }

  snackBarNotification(mensagem, tipo) {
    this.notificacao.open(mensagem, '', {
      duration: 4000,
      verticalPosition: 'top',
      horizontalPosition: 'end',
      panelClass: [tipo]
    });
  }

  carregarServicos() {
    this.servicosService.listarServicos().subscribe(result => {
      this.servicos = result.map(servico => {
        return { ...servico, selected: false };
      });
    }, error => {
      console.log(error);
    });
  }

  buscarConstantes() {
    this.constantesService.listarConstantes().subscribe(response => {
      this.constantes = response;
    }, error => {
      console.log(error)
    })
  }

  // atualizarSelecao(servico: Servico) {
  //   servico.selected = servico.estagiario || servico.colaborador || servico.gestor;
  // }

  atualizarSelecao(servico: Servico) {
    if (servico.estagiario || servico.colaborador || servico.gestor) {
      servico.selected = true;
    } else {
      servico.selected = false;
    }
  }

  buscarPermissoes(idSistema: string, idModulo: string) {
    this.permissoesService.listarPermissoes(idSistema, idModulo).subscribe(response => {
      this.permissoes = response;
      this.servicos.forEach(servico => {
        const permissaoEncontrada = this.permissoes.find(perm => perm.servico.identificador === servico.identificador);
        if (permissaoEncontrada) {
          servico.estagiario = permissaoEncontrada.flagAcessoEstagiario;
          servico.colaborador = permissaoEncontrada.flagAcessoColaborador;
          servico.gestor = permissaoEncontrada.flagAcessoGestor;
          servico.idPermissao = permissaoEncontrada.id;
          servico.selected = true;
        }
      });
      this.carregamentoTabela = false;
    }, error => {
      console.log(error);
    });
  }

  encontrarCorrespondencia(id: number): boolean {
    const permissaoEncontrada = this.permissoes.find(perm => perm.servico.id === id);
    return permissaoEncontrada ? true : false;
  }

}
