import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: `Basic ${btoa('engine:engine')}`,
  })
};

@Injectable({
  providedIn: 'root'
})
export class ConstantesService {

    constructor(private http: HttpClient) {}

    listarConstantes() {
        return this.http.get(`${environment.apiAdmin}constantes`, httpOptions);
      }

}
