import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VersionService {

  protected VERSION = 'v3.1.0';

  public get version() {
    return this.VERSION;
  }

  constructor() { }
}
