import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SolicitarAutenticacaoComponent } from './solicitar-autenticacao.component';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { LoginService } from 'src/app/modules/login/service/login-provider';
import { FormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  imports: [
    CommonModule,
    MatInputModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatTooltipModule,
    MatCardModule,
    MatDividerModule,
    FormsModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
  ],
  entryComponents: [
    SolicitarAutenticacaoComponent,
  ],
  declarations: [
    SolicitarAutenticacaoComponent, 
  ],
  exports: [
    SolicitarAutenticacaoComponent
  ],
  providers: [
    LoginService,
  ]
})
export class SolicitarAutenticacaoModule { }
