import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource, MatPaginator } from '@angular/material';
import { ModulosService } from '../services/modulos.service';
import { SolicitarAutenticacaoService } from 'src/app/shared/services/solicitar-autenticacao.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-detalhar',
  templateUrl: './detalhar.component.html',
  styleUrls: ['./detalhar.component.css']
})
export class DetalharComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  carregamentoTabela: boolean = true;
  carregamentoPermission: boolean = true;
  usuarioModulo = new MatTableDataSource();
  colunas: string[] = [
    "id",
    "matricula",
    "nome",
    "login",
    "cadastro"
  ];
  colunasPermission: string[] = [
    // "id",
    // "idModulo",
    // "idService",
    "chave",
    "descricao",
    "nivelPermissao",
  ]
  permissions;
  niveis = [
    { value: 3, cargo: '1 - Estagiário'},
    { value: 2, cargo: '2 - Analista'},
    { value: 1, cargo: '3 - Gestor'},
    { value: 0, cargo: '0 - Nenhum'},
  ]

  ngOnInit() {
    this.listarUsuariosModulo();
    this.listarPermissoesModulo()
    this.usuarioModulo.paginator = this.paginator;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public modulo,
    private dialogRef: MatDialogRef<DetalharComponent>,
    private modulosService: ModulosService,
    private autenticacao: SolicitarAutenticacaoService,
    private notificacao: MatSnackBar,
  ) { }

  fecharDetalharModulo() {
    this.dialogRef.close();
  }

  snackBarNotification(mensagem, tipo){
    this.notificacao.open(mensagem, '', {
      duration: 4000,
      verticalPosition: 'top',
      horizontalPosition: 'end',
      panelClass: [tipo]
    });
  }

  listarUsuariosModulo(){
    this.carregamentoTabela = true;
    this.modulosService.listarUsuariosModulos(this.modulo.id)
    .subscribe((resposta: any)=>{
      this.usuarioModulo.data = resposta;
      this.carregamentoTabela = false;
      console.table(resposta);
    })
  }

  listarPermissoesModulo(){
    this.carregamentoTabela = true;
    this.modulosService.listarPermissoesModulos(this.modulo.id)
    .subscribe((resposta: any)=>{
      this.permissions = resposta;
      this.carregamentoPermission = false;
      console.table(resposta);
    })
  }

}
