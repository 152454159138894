import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { CadastrarComponent } from '../cadastrar/cadastrar.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SolicitarAutenticacaoService } from 'src/app/shared/services/solicitar-autenticacao.service';
import { UsuariosService } from '../services/usuarios.service';
import { ModulosService } from '../../modulos/services/modulos.service';
import { UsuariosModulosService } from '../services/usuarios-modulos.services';
import { UsuariosModulosSgiService } from '../services/usuarios-modulos-sgi.services';
import { ConstantesService } from 'src/app/shared/services/constantes.service';

@Component({
  selector: 'app-usuario-modulo',
  templateUrl: './usuario-modulo.component.html',
  styleUrls: ['./usuario-modulo.component.css']
})
export class UsuarioModuloComponent implements OnInit {

  formularioUsuarioModulo: FormGroup;
  carregandoCadastro = false;
  visivel = false;
  user: any;
  modulos: any = [];
  constantes: any = [];
  usuarioModulo: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public usuario,
    private dialogRef: MatDialogRef<CadastrarComponent>,
    private formBuilder: FormBuilder,
    private usuariosService: UsuariosService,
    private modulosService: ModulosService,
    private usuarioModuloSerice: UsuariosModulosService,
    private usuarioModuloSgiService: UsuariosModulosSgiService,
    private constantesService: ConstantesService,
    private autenticacao: SolicitarAutenticacaoService,
    private notificacao: MatSnackBar,
  ) {

  }

  ngOnInit() {
    this.buscarUsuario(this.usuario.id);
    this.buscarModulos();
    this.buscarConstantes();
    this.carregarUsuarioModulo();
    this.formularioUsuarioModulo = this.formBuilder.group(
      {
        id: [''],
        modulo: ['', Validators.required],
        nivelAcesso: ['', Validators.required],
        relatorModulo: ['']
      }
    );
  }

  closeModal() {
    this.dialogRef.close();
  }

  onSubmit() {
    if (this.formularioUsuarioModulo.valid) {
      this.vincularUsuarioModulo(this.formularioUsuarioModulo.value);
    }
  }

  vincularUsuarioModulo(dados) {
    this.carregandoCadastro = true;
    this.autenticacao.abrirDialogo().subscribe(resposta => {
      if (resposta) {
        const data = {
          id: dados.id,
          usuario: {
            id: this.usuario.id
          },
          modulo: {
            id: dados.modulo
          },
          tipoUsuario: 'COLABORADOR',
          nivelAcesso: dados.nivelAcesso
        }
        console.log(data)
        this.usuarioModuloSerice.vincularUsuarioModulo(data).subscribe((res: any) => {
          const data2 = {
            id: res.id,
            usuario: {
              id: this.usuario.id
            },
            modulo: {
              id: dados.modulo
            }
          }
          this.usuarioModuloSgiService.vincularUsuarioModulo(data2).subscribe(result => {
            if (result) {
              let relator = null;
              if (dados.relatorModulo === true) {
                relator = dados.modulo;
              }
              this.usuariosService.relatorModulo(this.usuario.id, relator).subscribe(result => {  
                this.formularioUsuarioModulo.reset();
                this.carregandoCadastro = false;
                this.snackBarNotification('Usuario Módulo cadastrado com sucesso!', 'sucesso');
                this.dialogRef.close(true);
              }, err => {
                this.carregandoCadastro = false;
                this.snackBarNotification(err.error.message, 'erro');
              });
            }
          },
          err => {
            this.carregandoCadastro = false;
            this.snackBarNotification(err.error.message, 'erro');
          })
        },
        err => {
          this.carregandoCadastro = false;
          this.snackBarNotification(err.error.message, 'erro');
        })
      } else {
        this.carregandoCadastro = false;
      }
    })
  }

  carregarUsuarioModulo() {
    this.usuarioModuloSerice.carregarUsuarioModulo(this.usuario.id).subscribe(result => {
      this.usuarioModulo = result;

      // Preencher campos do formulário com os valores do usuarioModulo
      this.formularioUsuarioModulo.patchValue({
        id: this.usuarioModulo.id,
        modulo: this.usuarioModulo.modulo.id,
        nivelAcesso: this.usuarioModulo.nivelAcesso
      });
    });
  }

  buscarUsuario(id) {
    this.usuariosService.getUsuario(this.usuario.id).subscribe(response => {
      this.user = response;
    }, error => {
      console.log(error)
    })
  }

  buscarModulos() {
    this.modulosService.listarModulos().subscribe(response => {
      this.modulos = response;
    }, error => {
      console.log(error)
    })
  }

  buscarConstantes() {
    this.constantesService.listarConstantes().subscribe(response => {
      this.constantes = response;
    }, error => {
      console.log(error)
    })
  }

  snackBarNotification(mensagem, tipo) {
    this.notificacao.open(mensagem, '', {
      duration: 4000,
      verticalPosition: 'top',
      horizontalPosition: 'end',
      panelClass: [tipo]
    });
  }


}
