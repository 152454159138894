import { Component, OnInit, ViewChild } from '@angular/core';
import { UsuariosService } from './services/usuarios.service';
import { MatTableDataSource, MatPaginator, MatSnackBar } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { CadastrarComponent } from './cadastrar/cadastrar.component';
import { SolicitarAutenticacaoService } from 'src/app/shared/services/solicitar-autenticacao.service';
import { UsuarioModuloComponent } from './usuario-modulo/usuario-modulo.component';
import { Usuario } from '../login/models/usuario';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.css']
})
export class UsuariosComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  carregamentoTabela = true;
  dados = new MatTableDataSource();
  colunas: string[] = [
    'id',
    'matricula',
    'nome',
    'login',
    'email',
    'cadastro',
    'acao',
    'modulos'
  ];

  constructor(
    public usuarioService: UsuariosService,
    public dialog: MatDialog,
    private autenticacao: SolicitarAutenticacaoService,
    private notificacao: MatSnackBar,
  ) { }

  ngOnInit() {
    this.listarTodosUsuarios();
    this.dados.paginator = this.paginator;
  }

  atualizarTextosBotoes() {
    // Percorra cada registro e atualize o texto do botão com base no valor do campo ativo
    this.dados.data.forEach((registro: any) => {
      registro.textoBotao = registro.ativo === 'true' ? 'Desativar' : 'Ativar';
      console.log(registro)
    });
  }

  listarTodosUsuarios() {
    this.usuarioService.listarUsuarios().
      subscribe((resposta: any) => {
        this.dados.data = resposta
        this.carregamentoTabela = false;
        this.atualizarTextosBotoes(); // Atualiza os textos dos botões após receber os dados
        // console.table(resposta);
      }, error => {
        this.carregamentoTabela = false;
      })
  }

  toggleAtivo(registro: any) {
    console.log(registro.ativo)
    // tslint:disable-next-line:no-unused-expression
    // registro.ativo === true ? !registro.ativo : registro.ativo;
    // registro.ativo = !registro.ativo;
    // registro.textoBotao = registro.ativo === 'true' ? 'Desativar' : 'Ativar';
    this.revogarAcesso(registro.id);
    // console.log(registro)
    console.log(registro.ativo)
  }

  busca(value: string) {
    this.dados.filter = value.trim().toLocaleLowerCase();
  }

  openDialog() {
    const dialogRef = this.dialog.open(CadastrarComponent);

    dialogRef.afterClosed().subscribe(resultado => {
      if (resultado) {
        this.listarTodosUsuarios();
      }
    });
  }

  usuarioModulo(usuario: Usuario) {
    const dialogRef = this.dialog.open(UsuarioModuloComponent, {
      data: usuario
    });

    dialogRef.afterClosed().subscribe(resultado => {
      if (resultado) {
        this.listarTodosUsuarios();
      }
    });
  }

  revogarAcesso(registro: any) {
    console.log(registro.ativo)
    this.autenticacao.abrirDialogo()
    .subscribe(resposta => {
      if (resposta) {
        this.usuarioService.revogarAcesso(registro.id).subscribe(res => {
            this.snackBarNotification('Acesso Revogado!', 'sucesso');
            registro.ativo = registro.ativo === 'true' ? 'false' : 'true';
            registro.textoBotao = registro.ativo === 'true' ? 'Desativar' : 'Ativar';
            console.log(registro.ativo)
          },
          err => {
            this.snackBarNotification(err.error.message, 'erro');
          }
        );

      }
    })
  }

  snackBarNotification(mensagem, tipo) {
    this.notificacao.open(mensagem, '', {
      duration: 4000,
      verticalPosition: 'top',
      horizontalPosition: 'end',
      panelClass: [tipo]
    });
  }

}
