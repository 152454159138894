import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { MandatoryFields } from 'src/app/shared/models/mandatory-fields';
import { MandatoryFieldService } from 'src/app/shared/services/mandatory-field.service';
import { MandatoryFieldsFormComponent } from './mandatory-fields-form/mandatory-fields-form.component';

@Component({
  selector: 'app-mandatory-fields',
  templateUrl: './mandatory-fields.component.html',
  styleUrls: ['./mandatory-fields.component.css']
})
export class MandatoryFieldsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  carregamentoTabela: boolean = true;
  dados = new MatTableDataSource();
  colunas: string[] = [
    'id',
    'tableName',
    'fieldName',
    'mandatory',
    'edit'
  ];

  constructor(
    private mandatoryFieldService: MandatoryFieldService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.getAll();
    this.dados.paginator = this.paginator;
  }

  getAll(): void {
    this.carregamentoTabela = true;
    this.mandatoryFieldService.getAll().subscribe(response => {
      this.dados.data = response;
      this.carregamentoTabela = false;
    }, (error) => {
      console.log(error)
      this.carregamentoTabela = false;
    })
  }

  busca(value: string) {
    this.dados.filter = value.trim().toLocaleLowerCase();
  }

  cadastrarCampoObrigatorio() {
    const dialogRef = this.dialog.open(MandatoryFieldsFormComponent);

    dialogRef.afterClosed().subscribe(resultado => {
      if (resultado) {
        this.getAll();
      }
    });
  }

  editarCampo(campo: MandatoryFields) {
    const dialogRef = this.dialog.open(MandatoryFieldsFormComponent, {
      data: campo
    });
    dialogRef.afterClosed().subscribe(resultado => {
      if (resultado) {
        this.getAll();
      }
    });
  }

}
