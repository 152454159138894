import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './modules/login/login.component';
import { UsuariosComponent } from './modules/usuarios/usuarios.component';
import { ModulosComponent } from './modules/modulos/modulos.component';
import { MandatoryFieldsComponent } from './modules/mandatory-fields/mandatory-fields.component';

const routes: Routes = [
  { path: '', redirectTo: '/usuarios', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, data: { nomeSecao: 'Login' } },
  { path: 'usuarios', component: UsuariosComponent, data: { nomeSecao: 'Usuários' } },
  { path: 'modulos', component: ModulosComponent, data: { nomeSecao: 'Módulos' } },
  { path: 'mandatory-fields', component: MandatoryFieldsComponent, data: { nomeSecao: 'Campos Obrigatórios'} },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
